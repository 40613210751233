<template>
  <div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          :data="tableData">

        <el-table-column
            label="设备id"
            align="center"
            header-align="center"
            prop="id">
          <template slot-scope="scope">
            <span class="link_word" @click="goDetail(scope.row)">{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column
                label="设备名称"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
                prop="name">
        </el-table-column>
        <el-table-column
            label="异常货道编号"
            prop="wayNo"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
                label="RFID值"
                prop="rfid"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
        >
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        class="top_10 right right_10"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";
import {setMenuList} from "@/assets/js/common";

export default {
  name: "RfidAbnormal",
  mixins: [tableUse],
  data() {
    return {
      tableLoading:false,
      tableData: [
      ],
      statisticsInfo:{}
    }
  },
  methods: {
    goDetail(row) {
      setMenuList({
        path: '/deviceDetail',
        name: '设备详情',
        query:{id:row.id}
      })
      this.$router.push({name: 'DeviceDetail',query:{id:row.id}})
    },
    getTableData(){
      this.tableLoading = true;
      let formData = {
        pageNum:this.pageNum,
        pageSize: this.pageSize
      }
      this.$axios.post("/sys-api/device-api/selectRfidEx",formData,(res) => {
        this.tableLoading = false;
        if(res.code === '100'){
          this.total = res.data.total;
          this.tableData = res.data.list;
        }
      })
    }
  },
  mounted() {
    this.getTableData();
  }
}
</script>


<style scoped lang="scss">

</style>
